<template>

  <div>

    <div v-if="savedMortgagee && locked">
      <h3 style="display: inline-block;" class="pt-2 text-bold">{{savedMortgagee.MortgageeName}} {{$store.getters.getControlValue(module, suffixControl)}}</h3>
      <v-btn icon small color="secondary" @click="unlock()" class="ml-2" style="position: relative; top: -2px;" title="Edit Mortgagee" v-show="!disabled">
          <i class="fas fa-pen" style="font-size: 18px;"></i>
      </v-btn>
      <h3 class="mb-2">
        {{savedMortgagee.MortgageeAddress1}}<br>
        {{savedMortgagee.MortgageeCity}}, {{savedMortgagee.MortgageeState}} {{savedMortgagee.MortgageeZip}} {{savedMortgagee.MortgageeCountry}}
      </h3>
      <p class="mb-0">
        Loan Number: {{$store.getters.getControlValue(module, loanNumberControl)}}<br>
        <template v-if="invoiceControl">Invoice Upon Renewal: {{$store.getters.getControlValue(module, invoiceControl) ? 'Yes' : 'No'}}</template>
      </p>
    </div>

    <ValidationObserver tag="span" ref="mortgageeObserverV1" vid="MortgageeV1" v-slot="{}">
    <v-card class="pt-4 pb-2 px-2" v-if="!locked">
      <v-card-text>
          <v-row>
            <v-col md="12" cols="12" class="py-0 mb-3">
              <DynamicFormControl 
                  :control="{
                      name: 'MortgageeName',
                      type: 'TextInput',
                      value: MortgageeName,
                      config: {
                          rules: 'required',
                          label: 'Mortgagee Organization',
                          short: true
                      }
                  }"
              @change="updateMortgageeName"
              />
            </v-col>
          </v-row>
          
          <label class="mb-2 mt-2">Mortgagee Mailing Address:</label>

          <DynamicFormControl
            :key="JSON.stringify(mortgageeAddress)"
            :control="{
              type:'Address',
              name:'MortgageeAddress',
              value: mortgageeAddress,
              config: {
                addressType:'MAILING',
                label:null,
                rules:'addressLocked',
                col:12,
                short: true,
              }
            }"
            @change="updateMortgageeAddress"
          />

        <v-row>
          <v-col cols="12" class="py-0">
            <DynamicFormControl
              :control="{
                name: suffixControl,
                type: 'TextInput',
                value: selectedSuffix,
                config: {
                  label: 'Mortgagee Suffix',
                  clearable: true,
                  short: true,                 
                }
              }"
              @change="selectedSuffix = $event.value"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <DynamicFormControl
              :control="{
                name: 'LoanNumber',
                type: 'TextInput',
                value: selectedLoanNumber,
                config: {
                  rules: 'required',
                  label: 'Loan Number',
                  maxlength: 32,
                  short: true
                }
              }"
              @change="selectedLoanNumber = $event.value"
            />
          </v-col>
          <v-col cols="12" class="py-0" v-if="invoiceControl">
            <DynamicFormControl
              :control="{
                name: invoiceControl,
                type: 'Checkbox',
                value: invoiceOnRenewal,
                config: {
                  label: 'Invoice Upon Renewal',
                  short: true
                }
              }"
              @change="invoiceOnRenewal = $event.value"
            />
          </v-col>          
        </v-row>

        <div v-if="selectedMortgagee" class="preview">
          <br>
          <h3 style="display: inline-block;" class="pt-2 text-bold">{{selectedMortgagee.MortgageeName}} {{selectedSuffix}}</h3>
          <h3 class="mb-2">
            {{selectedMortgagee.MortgageeAddress1}}<br>
            {{selectedMortgagee.MortgageeCity}}, {{selectedMortgagee.MortgageeState}} {{selectedMortgagee.MortgageeZip}} {{selectedMortgagee.MortgageeCountry}}
          </h3>
          <p class="mb-0">
            Loan Number: {{selectedLoanNumber}}<br>
            <template v-if="invoiceControl">Invoice Upon Renewal: {{invoiceOnRenewal ? 'Yes' : 'No'}}</template>
          </p>
        </div>

        <div class="text-right">
          <template>
              <br>
              <FormButton v-bind="{label: 'Cancel', color: 'secondary', type: 'button', text: true}" @click="cancel()" v-if="savedMortgagee"></FormButton>
              <v-btn color="secondary" x-large type="button" @click="validateMortgagee" class="ml-2">Save Mortgagee</v-btn>
          </template>
        </div>
        


      </v-card-text>
    </v-card>
    </ValidationObserver>  

    <div v-if="!locked" class="hasError">
        <ValidationProvider rules="mortgageeOpen" v-slot="{ errors }">
            <input v-model="locked" hidden />
            <br>
            <ErrorMessages :errors="errors"></ErrorMessages>
        </ValidationProvider>
    </div>   
     
  </div>
  



</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import FormButton from '@/components/form/FormButton.vue'

export default {
  name: "MortgageeV1",
  components: {
    DynamicFormControl,
    FormButton
  },
  data() {
    return {
      locked: true,
      mode: 'SEARCH',
      MortgageeOrgV1: '',
      mortgageeOrgBranch: null,
      mortgageeAddress: null,
      MortgageeName: null,

      selectedMortgagee: null,
      selectedSuffix: null,
      selectedLoanNumber: null,
      invoiceOnRenewal: null,

      savedMortgagee: null,
      savedSuffix: null,
      savedLoanNumber: null,
      savedInvoice: null,
    }
  },
  props: {
    value: Object,
    rules: String,
    name: String,
    module: String,
    disabled: Boolean,
    suffixControl: String,
    loanNumberControl: String,
    invoiceControl: String
  },
  emits: [
    'change'
  ],
  watch: {
    value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            if (this.savedMortgagee != newVal) {
              this.savedMortgagee = newVal;
            }
            if (!newVal) {
              this.locked = false;
            }
          }
      }
  },
  computed: {
    mortgageeType() {
      return this.name === 'Mortgagee1Company' ? 'PrimaryMortgagee' : 'SecondaryMortgagee';
    }
  },
  methods: {
    formatSuffix(suffixCode) {
      let suffixFull = "";
      switch(suffixCode) {
        case "IA": suffixFull = "ISAOA ATIMA"; break;
        case "I": suffixFull = "ISAOA"; break;
        case "A": suffixFull = "ATIMA"; break;
        case "NA": suffixFull = ""; break;
      }
      return suffixFull;
    },
    unlock() {

      this.savedSuffix = this.$store.getters.getControlValue(this.module, this.suffixControl);
      this.savedLoanNumber = this.$store.getters.getControlValue(this.module,  this.loanNumberControl);
      if (this.invoiceControl) this.savedInvoice = this.$store.getters.getControlValue(this.module, this.invoiceControl);

      this.selectedSuffix = this.savedSuffix;
      this.selectedLoanNumber = this.savedLoanNumber;
      this.invoiceOnRenewal = this.savedInvoice;

      this.MortgageeName = this.savedMortgagee.MortgageeName;

      this.selectedMortgagee = {...this.savedMortgagee};

      this.mortgageeAddress = {
        StreetAddress: this.savedMortgagee.MortgageeAddress1,
        City: this.savedMortgagee.MortgageeCity,
        State: this.savedMortgagee.MortgageeState,
        PostalCode: this.savedMortgagee.MortgageeZip,
        CountryCode: this.savedMortgagee.MortgageeCountry == 'USA' ? 'US' : this.savedMortgagee.MortgageeCountry,
        Locked: true
      };
    
      this.locked = false;

    },
    lock() {
      this.$emit('change', this.selectedMortgagee);
      this.updateValue('MortgageeName',this.MortgageeName);
      this.updateValue(this.loanNumberControl, this.selectedLoanNumber);
      this.updateValue(this.suffixControl, this.selectedSuffix);

      if (this.invoiceControl) this.updateValue(this.invoiceControl, this.invoiceOnRenewal);

      // map the values to the v2 model
      const v2Model = {
        Address: this.mortgageeAddress,
        InvoiceOnRenewal: this.invoiceOnRenewal,
        LoanNumber: this.selectedLoanNumber,
        MortageeSuffix: this.selectedSuffix,
        MortgageeOrganization: this.MortgageeName
      }
      this.updateValue(this.mortgageeType, v2Model);

      this.locked = true;
    },
    cancel() {
      this.locked = true;
    },
    validateMortgagee() {
      this.$refs.mortgageeObserverV1.validate()
        .then(valid => {
            if (valid) {
              this.lock();
            }
        });
    },
    updateMortgageeAddress(event) {

      if (event && event.value) {
        this.mortgageeAddress = event.value;
        const obj = event.value;
        this.selectedMortgagee = {
          MortgageeName: this.MortgageeName,
          MortgageeAddress1: obj.StreetAddress,
          MortgageeCity: obj.City,
          MortgageeState: obj.State,
          MortgageeZip: obj.PostalCode,
          MortgageeCountry: obj.CountryCode
        }

      }
    },
    updateMortgageeName(event) {
      this.MortgageeName = event.value
      if (this.selectedMortgagee) {
        this.selectedMortgagee.MortgageeName = this.MortgageeName
      }
    },
    setSuffixCode(suffixCode) {
      if (!suffixCode) return;
      this.selectedSuffix = suffixCode;
    },
    updateValue(name, value) {
        this.$store.commit('updateFormControl', {
            module: this.module,
            name,
            value
        });
    },
    openFive9() {
      this.$store.dispatch('openFive9');
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

  ::v-deep .v-radio label {color: $gray1;}

  label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: $gray1;
    }

  .preview, p {
    color: #333333;
  }

</style>